// 本地存储用户信息
const UserKey = 'UserInfo_Key'
export const getInfo = () => {
  const userinfo = {
    token: '',
    userId: ''
  }
  const res = localStorage.getItem(UserKey)
  return res ? JSON.parse(res) : userinfo
}
export const setInfo = (obj) => {
  const Obj = JSON.stringify(obj)
  localStorage.setItem(UserKey, Obj)
}
export const removeInfo = () => {
  localStorage.removeItem(UserKey)
}
// 本地存储历史记录
const HistoryKey = 'History_key'
export const getHistory = () => {
  const res = localStorage.getItem(HistoryKey)
  return res ? JSON.parse(res) : []
}
export const setHistory = (arr) => {
  localStorage.setItem(HistoryKey, JSON.stringify(arr))
}
