import { getCart, upDateCart, delSelect } from '@/api/cart'
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
export default {
  namespaced: true,
  state () {
    return {
      cartList: []// 购物车列表
    }
  },
  mutations: {
    setCartList (state, newList) {
      state.cartList = newList
    },
    // 修改选择框
    ischecked (state, id) {
      const checked = state.cartList.find(item => item.goods_id === id)
      checked.isChecked = !checked.isChecked
    },
    // 全选
    allchecked (state, value) {
      state.cartList.forEach((item) => {
        item.isChecked = value
      })
    },
    // 更新购物车
    upDateCart (state, { goodsId, goodsNum }) {
      const res = state.cartList.find(item => item.goods_id === goodsId)
      res.goods_num = goodsNum
    }
  },
  getters: {
    // 获取商品总数
    cartTotal (state) {
      return state.cartList.reduce((sum, item) => sum + item.goods_num, 0)
    },
    // 选中的商品
    selList (state) {
      return state.cartList.filter(item => item.isChecked === true)
    },
    // 选中商品的总数
    selCount (state, getters) {
      return getters.selList.reduce((sum, item) => sum + item.goods_num, 0)
    },
    // 选中商品的总价
    selPrice (state, getters) {
      return getters.selList.reduce((sum, item) => sum + item.goods_num * item.goods.goods_price_min, 0).toFixed(2)
    },
    // 全选
    allCheValue (state) {
      return state.cartList.every(item => item.isChecked)
    }
  },
  actions: {
    async getCart (context) {
      const { data: { list } } = await getCart()
      list.forEach(item => {
        item.isChecked = true
      })
      context.commit('setCartList', list)
    },
    // 更新购物车
    async upDateCartAction (context, obj) {
      const { goodsId, goodsNum, goodsSkuId } = obj
      context.commit('upDateCart', { goodsId, goodsNum })
      await upDateCart(goodsId, goodsNum, goodsSkuId)
    },
    // 删除购物车
    async DelCartAction (context, selList) {
      const sel = selList
      const selId = sel.map(item => item.id)
      await delSelect(selId)
      await context.dispatch('getCart')
      Toast('删除成功')
    }
  }
}
