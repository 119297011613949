import axios from "axios";
import CryptoJS from 'crypto-js';
import Compressor from 'compressorjs';
import { Toast } from "vant";
import '@/utils/vant-ui'
import ImageCompressor from 'image-compressor.js'
// 加密函数
export function encryptData(data, key) {
    const encrypted = CryptoJS.AES.encrypt(data, key).toString();
    return encrypted;
}

// 解密函数
export function decryptData(encryptedData, key) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return originalData;
}
export function ys(file) {
  console.log(file.size);

  if (file) {
    return new Promise((resolve, reject) => {
      new ImageCompressor(file, {
        quality: 0,
        success: compressedFile => {
          // 在这里可以进行一些额外的处理，比如记录日志等
          console.log('文件已成功压缩：', compressedFile);
          resolve(compressedFile);
        },
        error: error => {
          console.log('压缩图片时出现错误：', error);
          reject(error);
        }
      });
    });
  }

}
export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string')) {
        if ((/^[0-9]+$/.test(time))) {
          // support "1548221490638"
          time = parseInt(time)
        } else {
          // support safari
          // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
          time = time.replace(new RegExp(/-/gm), '/')
        }
      }
  
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
      const value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
      return value.toString().padStart(2, '0')
    })
    return time_str
  }
  export function uploadFile(file) {
    // const maxSizeInBytes = 1024;
    // if (maxSizeInBytes < file.size) {
    //   Toast('请上传低于2MB图片')
    //   return
    // }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
    reader.onload = (e) => {
        const binaryData = new Uint8Array(e.target.result);
        const blob = new Blob([binaryData], { type: file.type });
        const formData = new FormData();
        formData.append('file', blob, file.name);
        formData.append('app', 'vue');
        axios.post('http://testadmin.sywycww.top/vueapi/business/uploadFile', formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          }
      })
          .then(response => {
              console.log(response);
              resolve (response.data)
          })
          .catch(error => {
              console.log('图片上传失败', error);
              reject(err)
          });
        // 后续构建FormData并上传的代码
    }; });
  }