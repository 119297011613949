import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/vant-ui'
import '@/styles/common.less'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
// import Vant from 'vant';
// import 'vant/lib/index.css';

// 根据本地localStorage存储的当前语言类型，切换不同语言
// import zhCN from 'vant/es/locale/lang/zh-CN'
// import enUS from 'vant/es/locale/lang/en-US'
// let lang = localStorage.getItem('lang') || 'zh_CN'
// if (lang === 'en') {
//   Locale.use('en-US', enUS)
// } else {
//   Locale.use('zh-CN', zhCN)
// }
// Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
