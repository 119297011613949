// 用户信息储存，以便随时可以拿到用户token
import { getInfo, setInfo } from '@/utils/storage'
export default {
  namespaced: true,
  state() {
    return {
      userInfo: getInfo(),
      familyid:0
    }
  },
  mutations: {
    setUserInfo(state, obj) {
      state.userInfo = obj
      setInfo(obj)
    },
    setfamilyid(state, obj) {
      state.familyid = obj
    }
  },
  getters: {},
  actions: {
    getuserinfo(context, obj) {
      context.commit('setUserInfo', obj)
    },
    getfamilyid(context, obj) {
      context.commit('setfamilyid', obj)
    },
    logout(context) {
      context.commit('setUserInfo', {})
      context.commit('cart/setCartList', [], { root: true })
    }
  }
}
