<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { encryptData, decryptData } from '@/utils/index';
export default {
  data() {
    return {
      info: {

      },
    }
  },
  created() {
    window.getUserToken = this.getUserToken();
    this.getinfo()
  },
  mounted() {
  },
  methods: {
    getUserToken(val) {
      this.info.token = val
    },
    decrypt(val) {
      let info = decryptData(val, 'JINHAILIVE_SSH');
      console.log(info);
    },
    getinfo() {

      // if (this.isAppleDevice()) {
      //   await window.webkit.messageHandlers.requestUserToken.postMessage(null)
      // } else {
      //   this.info.token = await window.Android.getUserToken()
      // }
      // 首先获取 location.hash 的值，它包含了URL中 # 后面的部分
      const hash = location.hash;

      // 然后从 hash 中提取出查询参数部分（即?uid=10003）
      const queryParams = hash.split('?')[1];
      // 再将查询参数按照 & 或 = 进行拆分，以便获取到具体的参数名和值
      if (queryParams) {
        const paramsArray = queryParams.split('&');
        for (let i = 0; i < paramsArray.length; i++) {
          const [paramName, paramValue] = paramsArray[i].split('=');


          if (paramName === 'info') {
            console.log(paramValue);
            console.log(decodeURIComponent(paramValue));

            let decodedData = atob(decodeURIComponent(paramValue));
            let info = JSON.parse(decodedData)
            console.log(info);
            this.info = JSON.parse(decodedData)
            this.info.uid = Number(info.uid)
            this.$store.dispatch('user/getuserinfo', this.info)
          }
        }
      }


      // this.info.token = "938401270133e6064d2a3ac1bc498591"
      // this.info.uid = 10061
      // this.$store.dispatch('user/getuserinfo', this.info)
      //  console.log(this.info);
      // this.info.token = '190918f864ed08a21c2af065e52f8c30'

    },
    isAppleDevice() {
      const userAgent = navigator.userAgent;
      return /iPhone|iPad|iPod/.test(userAgent);
    },
    isWeChatBrowser() {
      const ua = navigator.userAgent.toLowerCase();
      console.log(ua);

      return /micromessenger/.test(ua);
    }
  }
}
</script>
<style lang="less"></style>
