import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
const instance = axios.create({
  // baseURL: 'http://testadmin.sywycww.top',
  baseURL: 'https://live.sywycww.top',
  timeout: 10000
})
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // Toast.loading({
  //   message: '加载中...',
  //   forbidClick: true,
  //   duration: 0
  // })
  const token = store.getters.token
  console.log(token);
  
  if (token) {
    config.headers['token'] = store.getters.userInfo.token
    config.headers['uid'] = store.getters.userInfo.uid
  }
  console.log(config);


  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})
function isWeChatBrowser() {
  const ua = navigator.userAgent.toLowerCase();
  console.log(ua);

  return /micromessenger/.test(ua);
}
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  const res = response.data
  // if (res.status !== 200) {
  //   Toast(res.message)
  //   return Promise.reject(res.message)
  // }
  if (isWeChatBrowser) {
    if (res.data.code == 700) {
      store.dispatch('user/logout')
      Toast('您的账号已在其它渠道登录')
    }
  }
  Toast.clear()
  return res
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default instance
