import Vue from 'vue'
import { ShareSheet, Tag, Calendar, Uploader, Form, Picker, Popup, Rate, Icon, Search, Swipe, SwipeItem, Grid, GridItem, TabbarItem, Tabbar, NavBar, Toast, ActionSheet, Dialog, Checkbox, Cell, CellGroup, Tab, Tabs, Button, Field, RadioGroup, Radio } from 'vant'

Vue.use(Uploader)
Vue.use(ShareSheet)
Vue.use(Form)
Vue.use(Calendar)
Vue.use(Tag)
Vue.use(Popup)
Vue.use(RadioGroup)
Vue.use(Picker)
Vue.use(Radio)
Vue.use(Field)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(ActionSheet)
Vue.use(Rate)
Vue.use(Search)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Toast)
Vue.use(NavBar)
Vue.use(TabbarItem)
Vue.use(Tabbar)
Vue.use(CellGroup)
Vue.use(Cell)
