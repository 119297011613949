import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


const ContributionRank = () => import('@/views/ContributionRank/index.vue')
const Invitationrewards = () => import('@/views/Invitationrewards/index.vue')
const invitation = () => import('@/views/Invitationrewards/invitation.vue')
const invitationrule = () => import('@/views/Invitationrewards/rule.vue')
const Invitationincome = () => import('@/views/Invitationrewards/Invitationincome.vue')
const invitationWithdrawal = () => import('@/views/Invitationrewards/Withdrawal.vue')

Vue.use(VueRouter)
function isWeChatBrowser() {
  const ua = navigator.userAgent.toLowerCase();
  console.log(ua);

  return /micromessenger/.test(ua);
}
const router = new VueRouter({
  routes: [
    // { path: '/login', component: Login },
    {
      path: '/ContributionRank', component: ContributionRank,
      meta: {
        name: '贡献榜'
      }
    },
    {
      path: '/Invitationrewards', component: Invitationrewards,
      meta: {
        name: '邀请奖励'
      }
    },
    {
      path: '/yqinfo', component: () => import('@/views/Invitationrewards/yqinfo.vue'),
      meta: {
        name: '邀请奖励'
      }
    },
    {
      path: '/invitation', component: invitation,
      meta: {
        name: '我的邀请人'
      }
    },
    {
      path: '/invitationrule', component: invitationrule,
      meta: {
        name: '邀请收益规则'
      }
    },
    {
      path: '/Invitationincome', component: Invitationincome,
      meta: {
        name: '收益明细'
      }
    },
    {
      path: '/invitationWithdrawal', component: invitationWithdrawal,
      meta: {
        name: '申请提现'
      }
    },
    {
      path: '/invitationtxzh', component: () => import('@/views/Invitationrewards/txzh.vue'),
      meta: {
        name: '提现账户'
      }
    },
    {
      path: '/invitationtxjl', component: () => import('@/views/Invitationrewards/txjl.vue'),
      meta: {
        name: '提现记录'
      }
    },
    {
      path: '/banner/index1', component: () => import('@/views/banner/index1.vue'),
      meta: {
        name: '严禁私下交易'
      }
    },
    {
      path: '/banner/index2', component: () => import('@/views/banner/index2.vue'),
      meta: {
        name: '绿色文明公约'
      }
    },
    {
      path: '/banner/index3', component: () => import('@/views/banner/index3.vue'),
      meta: {
        name: '违规账号公告'
      }
    },
    {
      path: '/business', component: () => import('@/views/business/index.vue'),
      meta: {
        name: '商务合作'
      }
    },
    {
      path: '/sy/index', component: () => import('@/views/sy/Withdrawal.vue'),
      meta: {
        name: '我的珍珠'
      }
    },
    {
      path: '/sy/txzh', component: () => import('@/views/sy/txzh.vue'),
      meta: {
        name: '提现账户'
      }
    },
    {
      path: '/sy/txjl', component: () => import('@/views/sy/txjl.vue'),
      meta: {
        name: '提现记录'
      }
    },
    {
      path: '/anchor/index', component: () => import('@/views/anchor/index.vue'),
      meta: {
        name: '主播中心'
      }
    },
    {
      path: '/anchor/zbsj', component: () => import('@/views/anchor/zbsj.vue'),
      meta: {
        name: '直播数据'
      }
    },
    {
      path: '/family/index', component: () => import('@/views/family/index.vue'),
      meta: {
        name: '我的公会'
      }
    },
    {
      path: '/family/hall', component: () => import('@/views/family/hall.vue'),
      meta: {
        name: '我的公会'
      }
    },
    {
      path: '/family/create', component: () => import('@/views/family/create.vue'),
      meta: {
        name: '创建公会'
      }
    },
    
    {
      path: '/family/home', component: () => import('@/views/family/home.vue'),
      meta: {
        name: '公会主页'
      }
    },
    {
      path: '/family/familyinfo', component: () => import('@/views/family/familyinfo.vue'),
      meta: {
        name: '公会数据'
      }
    },
    {
      path: '/family/rule', component: () => import('@/views/family/rule.vue'),
      meta: {
        name: '公会规则'
      }
    },
    {
      path: '/family/zbsj', component: () => import('@/views/family/zbsj.vue'),
      meta: {
        name: '主播数据'
      }
    },
    {
      path: '/family/user', component: () => import('@/views/family/familyuser.vue'),
      meta: {
        name: '公会成员'
      }
    },
    {
      path: '/family/add', component: () => import('@/views/family/addanchor.vue'),
      meta: {
        name: '签约审核'
      }
    },
    {
      path: '/family/introduction', component: () => import('@/views/family/introduction.vue'),
      meta: {
        name: '签约审核'
      }
    },
    {
      path: '/family/del', component: () => import('@/views/family/deladdanchor.vue'),
      meta: {
        name: '解约审核'
      }
    },
    {
      path: '/family/Termination', component: () => import('@/views/family/Termination.vue'),
      meta: {
        name: '解除签约'
      }
    },
    {
      path: '/family/family_live_date', component: () => import('@/views/family/family_live_date.vue'),
      meta: {
        name: '主播明细'
      }
    },
    {
      path: '/family/zbsjfml', component: () => import('@/views/family/zbsjfml.vue'),
      meta: {
        name: '主播数据'
      }
    },
    {
      path: '/family/state', component: () => import('@/views/family/state.vue'),
      meta: {
        name: '审核状态'
      }
    },
    {
      path: '/feedback/index', component: () => import('@/views/feedback/index.vue'),
      meta: {
        name: '投诉与建议'
      }
    },
    {
      path: '/auth/index', component: () => import('@/views/auth/index.vue'),
      meta: {
        name: '实名认证'
      }
    },
    {
      path: '/auth/auth', component: () => import('@/views/auth/auth.vue'),
      meta: {
        name: '申请认证'
      }
    },
    {
      path: '/equipment/index', component: () => import('@/views/equipment/index.vue'),
      meta: {
        name: '我的装扮'
      }
    },
    {
      path: '/prop/index', component: () => import('@/views/prop/index.vue'),
      meta: {
        name: '道具商城'
      }
    },
    {
      path: '/prop/car', component: () => import('@/views/prop/car.vue'),
      meta: {
        name: '座骑'
      }
    },
    {
      path: '/prop/liang', component: () => import('@/views/prop/liang.vue'),
      meta: {
        name: '靓号'
      }
    },
    {
      path: '/level/index', component: () => import('@/views/level/index.vue'),
      meta: {
        name: '我的等级'
      }
    },
    {
      path: '/wx/invitation/index', component: () => import('@/views/wx/invitation/index.vue'),
      meta: {
        name: '我的推广'
      }
    },
    {
      path: '/wx/invitation/jlmx', component: () => import('@/views/wx/invitation/jlmx.vue'),
      meta: {
        name: '奖励明细'
      }
    },
    {
      path: '/wx/invitation/yqlb', component: () => import('@/views/wx/invitation/yqlb.vue'),
      meta: {
        name: '邀请人列表'
      }
    },
    {
      path: '/wx/invitation/yqdd', component: () => import('@/views/wx/invitation/yqdd.vue'),
      meta: {
        name: '邀请人订单'
      }
    },
    {
      path: '/wx/invitation/txjl', component: () => import('@/views/wx/invitation/txjl.vue'),
      meta: {
        name: '提现记录'
      }
    },
    {
      path: '/wx/invitation/Withdrawal', component: () => import('@/views/wx/invitation/Withdrawal.vue'),
      meta: {
        name: '申请提现'
      }
    },
    {
      path: '/wx/invitation/invitationtxzh', component: () => import('@/views/wx/invitation/txzh.vue'),
      meta: {
        name: '提现账户'
      }
    },
    {
      path: '/wx/invitation/rank', component: () => import('@/views/wx/invitation/rank.vue'),
      meta: {
        name: '充值排行'
      }
    },
  ]
})
// 导航全局守卫
// const authUrls = ['/pay', '/myorder']
router.beforeEach((to, from, next) => {
  // if (!authUrls.includes(to.path)) {
  //   next()
  // } else {
  //   if (store.getters.token) {
  document.title = to.meta.name || '金海';
  console.log(to);
  if (isWeChatBrowser()) {
    if (store.getters.userInfo.uid) {
      next()
    } else {
      if (to.path == '/wx/invitation/index') {
        next()
      } else {
        next('/wx/invitation/index')
      }
    }
  } else {
    next()
  }
  //   } else {
  //     next('/login')
  //   }
  // }
})
export default router
